/* App.css */
/* App.css */

/* Global Styles */
body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
  padding: 40px;
}

/* Main container */
.App {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  padding: 50px 0;
}

.App h1 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}

/* Form container */
.App label {
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
}

/* Form label */
.App label p {
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
}

/* Form list */
.App label ul {
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  padding: 0;
}

.App label ul li {
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 10px;
}

.App textarea {
  display: flex;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  height: 300px;
  padding: 20px;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  resize: none;
  box-shadow: 0px 0px 10px 0px #e6e6e6;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #dddddd;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.center {
  text-align: center;
}

p {
  margin: 10px 0;
  font-size: 18px;
  line-height: 1.5;
}

button {
  background-color: #00a699;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

/* To style the link */
a {
  color: #00a699;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
